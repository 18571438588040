import React from "react"
import { useTranslation } from "react-i18next"
import Img from "gatsby-image/withIEPolyfill"

import styles from "./features-item.module.css"

export default function FeaturesItem({ image, title, text }) {
  const { t } = useTranslation()

  return (
    <div className={`col-md-4`}>
      <Img
        fluid={image}
        objectFit="cover"
        objectPosition="50% 50%"
        className={styles.featuresItemImage}
        alt={`${t("image.alt")} ${title}`}
      />
      <h2 className={styles.featuresItemTitle}>{title}</h2>
      <p className={styles.featuresItemText}>{text}</p>
    </div>
  )
}
