import React from "react"

import styles from "./faqs-item.module.css"

export default function FaqsItem({ title, children, classVariant }) {
  return (
    <li
      className={`${styles.faqsItem} ${
        classVariant ? styles[classVariant] : ""
      }`}
    >
      <h3 className={styles.faqsItemTitle}>{title}</h3>
      <p className={styles.faqsItemAnswer}>{children}</p>
    </li>
  )
}
