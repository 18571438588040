import React from "react"

import styles from "./faqs.module.css"

export default function Faqs({ title, children, classVariant, id }) {
  return (
    <div className="row" id={id}>
      <div className="col-md-12">
        <h2 className={styles.faqsTitle}>{title}</h2>
        <div
          className={`${styles.faqsWrapper} ${
            classVariant ? styles[classVariant] : ""
          }`}
        >
          <ol className={styles.faqsList}>{children}</ol>
        </div>
      </div>
    </div>
  )
}
