import React from "react"

import styles from "./video.module.css"

export default function Video({ title, url }) {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <h3 className={styles.videoTitle}>{title}</h3>
        </div>
      </div>
      <div className="row">
        <div className={`col-sm-12 ${styles.videoWrapper}`}>
          <iframe
            src={url}
            frameborder="0"
            allowfullscreen
            title={title}
            className={styles.videoIframe}
          ></iframe>
        </div>
      </div>
    </>
  )
}
