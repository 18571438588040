import React from "react"

export default function Hr() {
  return (
    <div className="row">
      <div className="col-sm-12">
        <hr />
      </div>
    </div>
  )
}
